export function getScreenSaverOptionsByFormula(
  formula: "gamma_nl" | "gamma_be" | "karwei_nl",
  language: string
) {
  switch (formula) {
    case "gamma_nl":
      return new Map([
        [
          "Default",
          "https://videos.ctfassets.net/7k5qhlbm8iou/3tzHccUyiZocyPvBtsNoRb/7c8e35265b08dcbd9fd708b881e4a3cb/screensaver.mp4",
        ],
        [
          "Klusservice",
          "https://videos.ctfassets.net/7k5qhlbm8iou/56AhtTbBSmFQPanRmiDZrC/b24bdef01deb2733fab64190387a252f/Klusservice_portrait_mode_2021_V3_def__1_.webm",
        ],
        [
          "Smarthome",
          "https://videos.ctfassets.net/ufyps4xa8qu5/7gAtXU6URHjd0SgjKNJGmj/49e2bbe76356d5533b75b44f4672bce5/Smart-Home.webm",
        ],
        // [
        //   "Klusservice (landscape)",
        //   "https://videos.ctfassets.net/ufyps4xa8qu5/HLkHcr42GV97VsvQ1dvs1/10a0ae26945eca6a19725180cffea49e/Klusservice.webm",
        // ],
        [
          "Duurzaamheid",
          "https://videos.ctfassets.net/ufyps4xa8qu5/36EdWPkvxmj5ItFJk5ezOY/9f79eeeff7f1194f8f24e9cef5d08266/Verduurzamen.webm",
        ],
        [
          "Raamdecoratie",
          "https://videos.ctfassets.net/7k5qhlbm8iou/4Wxzp7VCxrZRXDijhd6GEW/02c112cf107594c27bf34c839085bc6c/Screensaver-raamdeco-NL_DEF.webm",
        ],
      ])
    case "gamma_be":
      if (language === "nl-BE") {
        return new Map([
          [
            "Default",
            "https://videos.ctfassets.net/2oqwvxysf41a/8bxbFyel9qGSCBnlRiJKk/6f20d6bf1a5bbf1a77310baca9426780/GAMMA_BE_SCREENSAVER_NL_v3.mp4",
          ],
          [
            "Raamdecoratie",
            "https://videos.ctfassets.net/2oqwvxysf41a/2rMm5YGVFoiBX8bt88EgLw/57e1dc6f87bb6c04a78ae77e2b486546/Screensaver-raamdeco-NL_DEF.webm",
          ],
        ])
      }

      return new Map([
        [
          "Default",
          "https://videos.ctfassets.net/2oqwvxysf41a/8bxbFyel9qGSCBnlRiJKk/6081ce3c23f3a8968a1b8c7b5671163e/GAMMA_BE_SCREENSAVER_FR_v3.mp4",
        ],
        [
          "Décoration de fenêtre",
          "https://videos.ctfassets.net/2oqwvxysf41a/2rMm5YGVFoiBX8bt88EgLw/fe19a21ab98ef84af49a3e0f5f8b4793/Screensaver_-_raamdecoratie_FR_DEF.webm",
        ],
      ])
    case "karwei_nl":
      return new Map([
        [
          "Default",
          "https://videos.ctfassets.net/ufyps4xa8qu5/jbMvVojV88rV6Ra3mN300/4f14a07b8b6bca9c02d8c1e5c4f2e0fe/KH_Screensavers_bestelzuil_Algemeen.mp4",
        ],
        [
          "Vloeren",
          "https://videos.ctfassets.net/ufyps4xa8qu5/560bXkxWhxdGTv078NU1uC/244f4635354f21820313133b8c282dfb/KH_Screensavers_bestelzuil_Vloeren.mp4",
        ],
        [
          "Verlichting",
          "https://videos.ctfassets.net/ufyps4xa8qu5/1r6qrgTpBDgIvkZZk0zqJi/1cb46a854d91f9d35dbde5a57190dce2/KH_Screensavers_bestelzuil_Verlichting.mp4",
        ],
        [
          "Raamdecoratie",
          "https://videos.ctfassets.net/ufyps4xa8qu5/76opSvethBvxnmeaa3VNNU/13a96e0416a34afa11a18d70f793cebd/KH_Screensavers_bestelzuil_Raamdeco.mp4",
        ],
        [
          "Sanitair",
          "https://videos.ctfassets.net/ufyps4xa8qu5/6R16JUILoljOsJ8is6aGk9/69ef8fc47eadb291da1ff4442f0c0d72/KH_Screensavers_bestelzuil_Sanitair.mp4",
        ],
        [
          "Default (oud)",
          "https://videos.ctfassets.net/ufyps4xa8qu5/1eprpcKN9NqyrdNo1H2TX0/7a2795378ad3f527d455c544998c85f3/screensaver.mp4",
        ],
        [
          "Raamdecoratie (oud)",
          "https://videos.ctfassets.net/ufyps4xa8qu5/5oDfGsgfwXPxEGtBuc04av/b6aac7d99e0db65171a6dd94cf10b9c7/screensaverraamdeco.mp4",
        ],
      ])
    default:
      return new Map()
  }
}
